import React, { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import './App.css';
import VisitBookingsList from './components/bookingsList/VisitBookingsList';
import { IVisitBooking } from './models/IVisitBooking';
import BookingsApi from './api/BookingsApi';
import QrReader from 'react-qr-reader';
import { Accordion, Button, Container, Grid, Icon, Menu, Modal, Segment, SemanticCOLORS } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import BookingDetails from './components/bookingDetails/BookingDetails';
import LoginForm from './components/login/LoginForm';
import { IBirthdayBooking } from './models/IBirthdayBooking';
import BirthdayBookingsList from './components/bookingsList/BirthdayBookingsList';
import TopMenu from './components/topMenu/TopMenu';
import StatsPerCity from './components/stats/StatsPerCity';
import StatsPerDay from './components/stats/StatsPerDay';
import StatsPerMonth from './components/stats/StatsPerMonth';
import StatsPerReferrer from './components/stats/StatsPerReferrer';
import StatsApi from './api/StatsApi';
import { IGroupBooking } from './models/IGroupBooking';
import GroupBookingsList from './components/bookingsList/GroupBookingsList';
import useInterval from './components/useInterval/UseInterval';
import { ISpecialEvent } from './models/ISpecialEvent';
import SpecialEventsList from './components/bookingsList/SpecialEventsList';
import { IBookingSummary } from './models/IBookingSummary';
import BookingCalendar from './components/bookingCalendar/BookingCalendar';
import dayjs from 'dayjs';

const App: React.FC = () => {
  const [visitBookings, setVisitBookings] = useState<IVisitBooking[]>([]);
  const [birthdayBookings, setBirthdayBookings] = useState<IBirthdayBooking[]>([]);
  const [groupBookings, setGroupBookings] = useState<IGroupBooking[]>([]);
  const [specialEvents, setSpecialEvents] = useState<ISpecialEvent[]>([]);
  const [bookingSummaryItems, setBookingSummaryItems] = useState<IBookingSummary[]>([]);
  const [bookingsLoaded, setBookingsLoaded] = useState<boolean>(false);
  const [calendarWeekLoaded, setCalendarWeekLoaded] = useState<boolean>(false);
  const [calendarDay, setCalendarDay] = useState<Date>();
  const [activeMenuItem, setActiveMenuItem] = useState<string>('today');
  const [displayMode, setDisplayMode] = useState<'visits' | 'birthdays' | 'specialevents' | 'stats' | 'groups' | 'calendar' | undefined>('visits');
  const [errorMessage, setErrorMessage] = useState<string>();
  const [errorMessageQRCode, setErrorMessageQRCode] = useState<string>();
  const today = (new Date()).toISOString().substr(0, 10);
  const [password, setPassword] = useState<string>();
  const yesterday = new Date(new Date().getTime() - (24 * 60 * 60 * 1000)).toISOString().substr(0, 10);
  const tomorrow = new Date(new Date().getTime() + (24 * 60 * 60 * 1000)).toISOString().substr(0, 10);
  const twoWeeksAgo = new Date(new Date().getTime() - (14 * 24 * 60 * 60 * 1000)).toISOString().substr(0, 10);  
  const [scanning, setScanning] = useState<boolean>(false);
  const [scannedBooking, setScannedBooking] = useState<IVisitBooking>();
  const initPassword = window.localStorage.getItem('password');
  const qrCodeRef = createRef<QrReader>();
  const [api, setApi] = useState<BookingsApi>(); // new BookingsApi(password);
  const [statsApi, setStatsApi] = useState<StatsApi>(); // new BookingsApi(password);
  const [activeStats, setActiveStats] = useState<string>();

  useInterval(() => {
    if (api && bookingsLoaded && !scanning) {
      api.getVisitBookings().then((resVisits) => {
        setVisitBookings(resVisits.data || []);
      });
    }
  }, 10000);

  useEffect(() => {
    if ((!calendarWeekLoaded || calendarDay) && api) {
      var firstDateOfWeek = calendarDay ? dayjs(calendarDay) : dayjs();
      if (firstDateOfWeek.day() > 1) {
        firstDateOfWeek = firstDateOfWeek.add(-1 * (firstDateOfWeek.day() - 1), 'day');
      } 
      else if (firstDateOfWeek.day() === 0){
        firstDateOfWeek = firstDateOfWeek.add(-6, 'day');
      }

      api.getCalendarBookings(firstDateOfWeek.format('YYYY-MM-DD'), firstDateOfWeek.add(7, 'day').format('YYYY-MM-DD'))
        .then((res) => {
          setBookingSummaryItems(res.data);
          setCalendarWeekLoaded(true);
        });
    }
  }, [calendarWeekLoaded, api, calendarDay]);


  const changeCalendarDay = (newDate: Date) => {
    setCalendarDay(newDate);
  }

  useEffect(() => {
    if (!bookingsLoaded && api) {
      console.log('Load bookings');
      Promise.all([api.getVisitBookings(), api.getBirthdayBookings(), api.getGroupBookings(), api.getSpecialEvents()]).then((res) => {
        setVisitBookings(res[0].data || []);
        setBirthdayBookings(res[1].data || []);
        setGroupBookings(res[2].data || []);
        setSpecialEvents(res[3].data || []);
      }).finally(() => {
        setBookingsLoaded(true);
      });
    }
  }, [api, bookingsLoaded, password]);

  const currentBookings = useMemo<IVisitBooking[]>(() => {
    if (bookingsLoaded) {
      switch (activeMenuItem) {
        case 'today':
          return visitBookings.filter(b => b.eventDate.startsWith(today));
        case 'next':
          return visitBookings.filter(b => b.eventDate >= tomorrow);
        case 'yesterday':
          return visitBookings.filter(b => b.eventDate.startsWith(yesterday));
      }
    }
    return [];
  }, [today, yesterday, tomorrow, bookingsLoaded, activeMenuItem, visitBookings]);

  const currentBirthdayBookings = useMemo<IBirthdayBooking[]>(() => {
    if (bookingsLoaded) {
      switch (activeMenuItem) {
        case 'today':
          return birthdayBookings.filter(b => b.eventDate.startsWith(today));
        case 'next':
          return birthdayBookings.filter(b => b.eventDate >= tomorrow);
        case 'yesterday':
          return birthdayBookings.filter(b => b.eventDate.startsWith(yesterday));
      }
    }
    return [];
  }, [today, yesterday, tomorrow, bookingsLoaded, activeMenuItem, birthdayBookings]);

  const currentGroupBookings = useMemo<IGroupBooking[]>(() => {
    if (bookingsLoaded) {
      switch (activeMenuItem) {
        case 'today':
          return groupBookings.filter(b => b.eventDate.startsWith(today));
        case 'next':
          return groupBookings.filter(b => b.eventDate >= tomorrow);
        case 'last':
          return groupBookings.filter(b => b.quoteValidationDate && b.quoteValidationDate >= twoWeeksAgo);
        case 'yesterday':
          return groupBookings.filter(b => b.eventDate.startsWith(yesterday));
      }
    }
    return [];
  }, [today, yesterday, tomorrow, bookingsLoaded, activeMenuItem, groupBookings, twoWeeksAgo]);

  const currentSpecialEvents = useMemo<ISpecialEvent[]>(() => {
    if (bookingsLoaded) {
      switch (activeMenuItem) {
        case 'today':
          return specialEvents.filter(b => b.eventDate.startsWith(today));
        case 'next':
          return specialEvents.filter(b => b.eventDate >= tomorrow);
        case 'yesterday':
          return specialEvents.filter(b => b.eventDate.startsWith(yesterday));
      }
    }
    return [];
  }, [today, yesterday, tomorrow, bookingsLoaded, activeMenuItem, specialEvents]);

  const handleQRScan = (bookingId: string | null) => {
    console.log(bookingId);
    if (bookingId && api) {
      api.getVisitBooking(bookingId).then((booking) => {
        if (booking && booking.data) {
          setScannedBooking(booking.data);
          setErrorMessageQRCode(undefined);
        }
        else {
          setErrorMessageQRCode('Ce QRCode ne correspond pas à une des réservations courantes.')
        }
      });
    } else {
      setErrorMessageQRCode('Ce QRCode est illisible')
    }
  }

  const login = (pwd: string) => {
    console.log('login');
    window.localStorage.setItem('password', pwd);
    setPassword(pwd);
    setApi(new BookingsApi(pwd));
    setStatsApi(new StatsApi(pwd));
  }

  const logout = () => {
    console.log('logout');
    window.localStorage.removeItem('password');
    setPassword(undefined);
    setApi(undefined);
    setStatsApi(undefined);
    setBookingsLoaded(false);
  }

  const refreshBookings = () => {
    if (api) {
      setBookingsLoaded(false);
    }
  };

  const validateQRCode = () => {
    if (api && scannedBooking) {
      api.updateVisitBookingShow(scannedBooking.bookingId, true).then((resUpdate) => {
        if (resUpdate.data) {
          api.getVisitBookings().then((res) => {
            const b = res.data.find(f => f.bookingId === scannedBooking.bookingId);
            if (b) b.show = true;
            setVisitBookings(res.data)
            setScanning(false);
            setErrorMessageQRCode(undefined);
            setScannedBooking(undefined);
          });
        } else {
          setErrorMessage(`Problème lors de la mise à jour de la réservation ${scannedBooking.bookingId}`);
        }
      });
    }
  }

  const updateShow = (id: string, show: boolean) => {
    if (api) {
      api.updateVisitBookingShow(id, show).then((resUpdate) => {
        if (resUpdate.data) {
          api.getVisitBookings().then((res) => {
            const b = res.data.find(f => f.bookingId === id);
            if (b) b.show = true;
            setVisitBookings(res.data)
          });
        } else {
          setErrorMessage(`Problème lors de la mise à jour de la réservation ${id}`);
        }
      });
    }
  }

  const updateBirthdayShow = (id: string, show: boolean) => {
    if (api) {
      api.updateBirthdayBookingShow(id, show).then((resUpdate) => {
        if (resUpdate.data) {
          api.getBirthdayBookings().then((res) => {
            const b = res.data.find(f => f.bookingId === id);
            if (b) b.show = true;
            setBirthdayBookings(res.data)
          });
        } else {
          setErrorMessage(`Problème lors de la mise à jour de la réservation pour l'anniversaire ${id}`);
        }
      });
    }
  }

  const updateGroupShow = (id: string, show: boolean) => {
    if (api) {
      api.updateGroupBookingShow(id, show).then((resUpdate) => {
        if (resUpdate.data) {
          api.getGroupBookings().then((res) => {
            const b = res.data.find(f => f.bookingId === id);
            if (b) b.show = true;
            setGroupBookings(res.data)
          });
        } else {
          setErrorMessage(`Problème lors de la mise à jour du groupe`);
        }
      });
    }
  }

  const updateSpecialEventBookingShow = (id: string, show: boolean) => {
    if (api) {
      api.updateSpecialEventBookingShow(id, show).then((resUpdate) => {
        if (resUpdate.data) {
          api.getSpecialEvents().then((res) => {
            setSpecialEvents(res.data);
          });
        } else {
          setErrorMessage(`Problème lors de la mise à jour du groupe`);
        }
      });
    }
  }

  const updateGroupStatus = (id: string, status: 'PREBOOKED' | 'BOOKED' | 'REJECTED'): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      if (api) {
        api.updateGroupBookingStatus(id, status).then((resUpdate) => {
          if (resUpdate.data) {
            api.getGroupBookings().then((res) => {
              const b = res.data.find(f => f.bookingId === id);
              if (b) b.show = true;
              setGroupBookings(res.data)
              resolve(true);
            });
          } else {
            setErrorMessage(`Problème lors de la mise à jour du groupe`);
            resolve(false);
          }
        });
      }
      else
        reject('no api');
    });
  }

  const openImageDialog = () => {
    if (qrCodeRef && qrCodeRef.current) qrCodeRef.current.openImageDialog();
  }

  const closeDialog = () => {
    setScanning(false);
    setErrorMessageQRCode(undefined);
    setScannedBooking(undefined);
  }

  const downloadQuoteFile = useCallback((booking: IGroupBooking) => {
    if (api) api.downloadQuoteFile(booking);
  }, [api]);

  const currentSectionColor = useMemo<SemanticCOLORS>(() => {
    switch (displayMode) {
      case 'visits':
        return 'green';
      case 'groups':
        return 'purple';
      case 'birthdays':
        return 'blue';
      case 'stats':
        return 'orange';
      case 'specialevents':
        return 'red';
        case 'calendar':
          return 'teal';
    }
    return 'black';
  }, [displayMode]);

  return (
    <Container fluid className="App" style={{ marginTop: 10 }}>
      {!password && (
        <>
          <h3>Ferme Rainbow - Réservations</h3>
          <LoginForm login={login} initPassword={initPassword} />
        </>
      )}
      {api && !displayMode && bookingsLoaded && (
        <TopMenu setDisplayMode={setDisplayMode} />
      )}
      {api && displayMode && (
        <>
          <Menu>
            <Menu.Item
              name='visites'
              active={displayMode === 'visits'}
              onClick={() => {
                setDisplayMode('visits');                
                setActiveMenuItem('today');
              }}
            />
            <Menu.Item
              name='groupes'
              active={displayMode === 'groups'}
              onClick={() => { 
                setDisplayMode('groups');
                setActiveMenuItem('next');
              }}
            />
            <Menu.Item
              name='anniv'
              active={displayMode === 'birthdays'}
              onClick={() => {
                setDisplayMode('birthdays');
                setActiveMenuItem('next');
              }}
            />
            <Menu.Item
              name='autre'
              active={displayMode === 'specialevents'}
              onClick={() => { 
                setDisplayMode('specialevents');
                setActiveMenuItem('next');
              }}
            />
            <Menu.Item
              name='planning'
              active={displayMode === 'calendar'}
              onClick={() => setDisplayMode('calendar')}
            />
            <Menu.Item
              name='stats'
              active={displayMode === 'stats'}
              onClick={() => setDisplayMode('stats')}
            />
          </Menu>
          <Menu fluid inverted color={currentSectionColor}>
            {displayMode !== 'stats' && displayMode !== 'calendar' && (
              <>
                <Menu.Item
                  name='today'
                  active={activeMenuItem === "today"}
                  onClick={() => setActiveMenuItem("today")}
                >Aujourd'hui</Menu.Item>
                <Menu.Item
                  name='next'
                  active={activeMenuItem === "next"}
                  onClick={() => setActiveMenuItem("next")}
                ></Menu.Item>
                {displayMode === 'groups' ? 
                  (
                    <Menu.Item
                      name='last'                      
                      onClick={() => setActiveMenuItem("last")}>
                      <Icon name="numbered list" title="Depuis 2 semaines"></Icon>
                    </Menu.Item>
                  ) :
                  (<Menu.Item
                    name='scan'
                    onClick={() => setScanning(true)}>
                    <Icon name="mobile alternate"></Icon>
                  </Menu.Item>
                  )}

              </>
            )}
            <Menu.Item
              name='refresh'
              onClick={refreshBookings}>
              <Icon name="refresh"></Icon>
            </Menu.Item>
            <Menu.Item
              name='logout'
              onClick={logout}>
              <Icon name="log out"></Icon>
            </Menu.Item>
          </Menu>
        </>
      )}
      {statsApi && displayMode === 'stats' && (
        <Accordion fluid styled>
          <Accordion.Title onClick={() => setActiveStats(activeStats !== 'city' ? 'city' : undefined)}>Top 10 - Visiteurs par ville d'origine</Accordion.Title>
          <Accordion.Content active={activeStats === 'city'}>
            <StatsPerCity api={statsApi} />
          </Accordion.Content>
          <Accordion.Title onClick={() => setActiveStats(activeStats !== 'days' ? 'days' : undefined)}>Visiteurs dernière semaine</Accordion.Title>
          <Accordion.Content active={activeStats === 'days'}>
            <StatsPerDay api={statsApi} />
          </Accordion.Content>
          <Accordion.Title onClick={() => setActiveStats(activeStats !== 'months' ? 'months' : undefined)}>Visiteurs 18 derniers mois</Accordion.Title>
          <Accordion.Content active={activeStats === 'months'}>
            <StatsPerMonth api={statsApi} />
          </Accordion.Content>
          <Accordion.Title onClick={() => setActiveStats(activeStats !== 'referrer' ? 'referrer' : undefined)}>Comment nous a-t-on connu</Accordion.Title>
          <Accordion.Content active={activeStats === 'referrer'}>
            <StatsPerReferrer api={statsApi} />
          </Accordion.Content>
        </Accordion>
      )}
      {api && displayMode === 'visits' && (
        <>
          <main>
            {
              errorMessage && (
                <Segment negative={true}>{errorMessage}</Segment>
              )
            }
            {bookingsLoaded && (
              <VisitBookingsList items={currentBookings} displayDate={activeMenuItem === "next"} updateShow={updateShow} />
            )}
            {!bookingsLoaded && (
              <Segment loading>Chargement en cours</Segment>
            )}
          </main>
          <Grid columns={1} style={{ marginTop: 10 }}>
            <Grid.Row>
              <Grid.Column>
                <Button icon inverted color='green' labelPosition='left' onClick={() => {
                  setScanning(true);
                }}>
                  <Icon name="mobile alternate"></Icon> Scanner
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {scanning && (
            <Modal
              size="fullscreen"
              onClose={closeDialog}
              open={scanning}
            >
              <Modal.Header>Scanner de réservation</Modal.Header>
              <Modal.Content image>
                <Modal.Description>
                  {!scannedBooking && (
                    <>
                      <QrReader ref={qrCodeRef} onScan={handleQRScan} delay={300} legacyMode={false} onError={() => { }} />
                    </>
                  )}
                  {scannedBooking && (
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={6}>
                          Numéro de réservation
                        </Grid.Column>
                        <Grid.Column width={10}>
                          {scannedBooking.bookingId}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={6}>
                          Nom / Prénom
                        </Grid.Column>
                        <Grid.Column width={10}>
                          {scannedBooking.lastname.toUpperCase()} {scannedBooking.firstname}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <div style={{ padding: 5 }}>
                            <BookingDetails nbIndividualsNLG={scannedBooking.individualNLG} nbFamiliesNLG={scannedBooking.familyNLG} nbIndividualsHNLG={scannedBooking.individualHNLG} nbFamiliesHNLG={scannedBooking.familyHNLG} nbFree={scannedBooking.free} />
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                      {scannedBooking.show && (
                        <Grid.Row>
                          <Grid.Column width={16}>
                            Réservation déjà validée
                          </Grid.Column>
                        </Grid.Row>
                      )}
                      <Grid.Row columns={2}>
                        <Grid.Column>
                          <Button
                            content="Annuler"
                            labelPosition="left"
                            icon="cancel"
                            onClick={() => {
                              setScannedBooking(undefined);
                            }}
                          />
                        </Grid.Column>
                        <Grid.Column textAlign="right">
                          {!scannedBooking.show && (
                            <Button
                              content="Valider"
                              labelPosition='right'
                              icon='checkmark'
                              positive
                              onClick={validateQRCode}
                            />
                          )}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  )}
                  {errorMessageQRCode && (
                    <Segment negative={true}>{errorMessageQRCode}</Segment>
                  )}
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button primary onClick={openImageDialog}>Charger depuis l'appareil photo</Button>
                <Button color='black' onClick={() => setScanning(false)}>
                  Fermer
                </Button>
              </Modal.Actions>
            </Modal>
          )}
        </>
      )}
      {api && displayMode === 'birthdays' && (
        <>
          <main>
            {
              errorMessage && (
                <Segment negative={true}>{errorMessage}</Segment>
              )
            }
            {bookingsLoaded && (
              <BirthdayBookingsList items={currentBirthdayBookings} displayDate={activeMenuItem === "next"} updateShow={updateBirthdayShow} />
            )}
            {!bookingsLoaded && (
              <Segment loading>Chargement en cours</Segment>
            )}
          </main>
        </>
      )}
      {api && displayMode === 'groups' && (
        <>
          <main>
            {
              errorMessage && (
                <Segment negative={true}>{errorMessage}</Segment>
              )
            }
            {bookingsLoaded && (
              <GroupBookingsList items={currentGroupBookings} updateShow={updateGroupShow} updateStatus={updateGroupStatus} downloadQuoteFile={downloadQuoteFile} />
            )}
            {!bookingsLoaded && (
              <Segment loading>Chargement en cours</Segment>
            )}
          </main>
        </>
      )}
      {api && displayMode === 'specialevents' && (
        <>
          <main>
            {
              errorMessage && (
                <Segment negative={true}>{errorMessage}</Segment>
              )
            }
            {bookingsLoaded && (
              <SpecialEventsList items={currentSpecialEvents} updateShow={updateSpecialEventBookingShow} />
            )}
            {!bookingsLoaded && (
              <Segment loading>Chargement en cours</Segment>
            )}
          </main>
        </>
      )}
      {api && displayMode === 'calendar' && (
        <>
          <main>
            {
              errorMessage && (
                <Segment negative={true}>{errorMessage}</Segment>
              )
            }
            {calendarWeekLoaded && (
              <BookingCalendar items={bookingSummaryItems} changeCalendarDay={changeCalendarDay} />
            )}
            {!calendarWeekLoaded && (
              <Segment loading>Chargement en cours</Segment>
            )}
          </main>
        </>
      )}
    </Container>
  );
}

export default App;

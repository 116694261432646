import React, { useEffect, useState } from 'react';
import { Button, Popup, Table } from 'semantic-ui-react';
import StatsApi from '../../api/StatsApi';
import IVisitBookingStatsPerReferrer from '../../models/IVisitBookingStatsPerReferrer';

interface StatsPerReferrerProps {
    api: StatsApi;
}

const StatsPerReferrer: React.FC<StatsPerReferrerProps> = (props: StatsPerReferrerProps) => {
    const [items, setItems] = useState<IVisitBookingStatsPerReferrer[]>();
    const [totalResults, setTotalResults] = useState<number>();
    
    useEffect(() => {
        if (props.api && !items) {
            props.api.getVisitBookingsStatsPerReferrer()
                .then((res) => {
                    setItems(res.data);
                    // Getting sum of numbers
                    const sum = res.data.map(d => d.results).reduce(function(a, b){
                        return a + b;
                    }, 0);
                    setTotalResults(sum);
                });
        }
    }, [props.api, items]);

    return (
        <>
            {items && (
                <Table celled striped unstackable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Connu par</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">%</Table.HeaderCell>
                            <Table.HeaderCell textAlign="right">Nombre</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            items.map(i => {
                            return (
                                <Table.Row key={i.id}>
                                    <Table.Cell>{i.mainReferrer}
                                    { i.mainReferrer === 'Autre' && (
                                        <Popup
                                        on='click'
                                        pinned
                                        size="mini"
                                        position='bottom right'
                                        trigger={<Button content='Détail' size="mini" floated="right" basic />}
                                      >{
                                        i.referrers?.map(r =>  {
                                            return (<><span>{r}</span><br/></>);
                                        })
                                      }
                                      </Popup>
                                    )}
                                    </Table.Cell>
                                    <Table.Cell collapsing textAlign="center">{Math.round((i.results / (totalResults || 1)) * 100)}</Table.Cell>
                                    <Table.Cell collapsing textAlign="right">{i.results}</Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            )}
        </>
    );
}

export default StatsPerReferrer;

import React from 'react';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button/Button';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon';

interface TopMenuProps {
    setDisplayMode: (mode:'visits' | 'birthdays' | undefined) => void;
}

const TopMenu: React.FC<TopMenuProps> = (props:TopMenuProps) => {

    return (<Grid style={{ marginTop: 50 }}>
        <Grid.Row>
          <Grid.Column>
            <Button icon labelPosition='left' size='huge' color='green' fluid onClick={() => props.setDisplayMode('visits')}>
              <Icon name='ticket alternate' />
              Visites
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Button icon labelPosition='left' size='huge' color='blue' fluid onClick={() => props.setDisplayMode('birthdays')}>
              <Icon name='birthday cake' />
              Anniversaires
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>);
};

export default TopMenu;
import React, { useEffect, useState } from 'react';
import { Table } from 'semantic-ui-react';
import StatsApi from '../../api/StatsApi';
import IVisitBookingStatsPerDay from '../../models/IVisitBookingStatsPerDay';

interface StatsPerDayProps {
    api: StatsApi;
}

const StatsPerDay: React.FC<StatsPerDayProps> = (props: StatsPerDayProps) => {
    const [items, setItems] = useState<IVisitBookingStatsPerDay[]>();

    useEffect(() => {
        if (props.api && !items) {
            props.api.getVisitBookingsStatsPerDay()
                .then((res) => {
                    setItems(res.data);
                });
        }
    }, [props.api, items]);

    return (
        <>
            {items && (
                <Table celled striped unstackable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.HeaderCell>Réservations</Table.HeaderCell>
                            <Table.HeaderCell>Visiteurs</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {items.map(i => {
                            const curDate = new Date(i.eventDate);
                            return (
                                <Table.Row key={i.eventDate}>
                                    <Table.Cell>{curDate.toLocaleDateString()}</Table.Cell>
                                    <Table.Cell>{i.bookings}</Table.Cell>
                                    <Table.Cell collapsing textAlign="right">{i.visitors}</Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            )}
        </>
    );
}

export default StatsPerDay;

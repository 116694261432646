import { AxiosResponse } from 'axios';
import { IGroupBooking } from '../models/IGroupBooking';
import { ISpecialEvent } from '../models/ISpecialEvent';
import { IVisitBooking } from '../models/IVisitBooking';
import BaseApi from './BaseApi';
import { IBookingSummary } from '../models/IBookingSummary';

/**
 * The BookingsApi class.
 *
 */
class BookingsApi extends BaseApi {
	private _password: string;

	constructor(password: string) {
		super('desk/bookings');
		this._password = password;
	}

	/** 
	 * Get visit bookings
	 *  
	 * @return {Promise} The request promise.
	*/
	public getVisitBookings = (): Promise<AxiosResponse<IVisitBooking[]>> => {
		return this._get('visits', {
			headers: {
				'Authorization': `Bearer ${this._password}`
			}
		});
	}

	/** 
	 * Get visit booking
	 *  
	 * @return {Promise} The request promise.
	*/
	public getVisitBooking = (id: string): Promise<AxiosResponse<IVisitBooking>> => {
		return this._get(`visits/${id}`, {
			headers: {
				'Authorization': `Bearer ${this._password}`
			}
		});
	}

	/** 
	 * Update visit booking show
	 *  
	 * @return {Promise} The request promise.
	*/
	public updateVisitBookingShow = (id: string, show: boolean): Promise<AxiosResponse<boolean>> => {
		return this._post('visits', {
			id,
			show
		}, {
			headers: {
				'Authorization': `Bearer ${this._password}`
			}
		});
	}
	
	/** 
	 * Get birthday bookings
	 *  
	 * @return {Promise} The request promise.
	*/
	public getBirthdayBookings = (): Promise<AxiosResponse<IVisitBooking[]>> => {
		return this._get('birthdays', {
			headers: {
				'Authorization': `Bearer ${this._password}`
			}
		});
	}

	/** 
	 * Update birthday booking show
	 *  
	 * @return {Promise} The request promise.
	*/
	public updateBirthdayBookingShow = (id: string, show: boolean): Promise<AxiosResponse<boolean>> => {
		return this._post('birthdays', {
			id,
			show
		}, {
			headers: {
				'Authorization': `Bearer ${this._password}`
			}
		});
	}

	/** 
	 * Get group bookings
	 *  
	 * @return {Promise} The request promise.
	*/
	public getGroupBookings = (): Promise<AxiosResponse<IGroupBooking[]>> => {
		return this._get('groups', {
			headers: {
				'Authorization': `Bearer ${this._password}`
			}
		});
	}

	/** 
	 * Get bookings as calendar
	 *  
	 * @return {Promise} The request promise.
	*/
	public getCalendarBookings = (startDate: string, endDate: string): Promise<AxiosResponse<IBookingSummary[]>> => {
		return this._get(`summary?startingFrom=${startDate}&endingTo=${endDate}`, {
			headers: {
				'Authorization': `Bearer ${this._password}`
			}
		});
	}

	/** 
	 * Update booking show
	 *  
	 * @return {Promise} The request promise.
	*/
	public updateGroupBookingShow = (id: string, show: boolean): Promise<AxiosResponse<boolean>> => {
		return this._post('groups', {
			id,
			show
		}, {
			headers: {
				'Authorization': `Bearer ${this._password}`
			}
		});
	}

	/** 
	 * Get special events
	 *  
	 * @return {Promise} The request promise.
	*/
	public getSpecialEvents = (): Promise<AxiosResponse<ISpecialEvent[]>> => {
		return this._get('specialevents', {
			headers: {
				'Authorization': `Bearer ${this._password}`
			}
		});
	}

	/** 
	 * Update special event booking show
	 *  
	 * @return {Promise} The request promise.
	*/
	public updateSpecialEventBookingShow = (id: string, show: boolean): Promise<AxiosResponse<boolean>> => {
		return this._post('specialevents', {
			id,
			show
		}, {
			headers: {
				'Authorization': `Bearer ${this._password}`
			}
		});
	}

	/** 
	 * Update booking status
	 *  
	 * @return {Promise} The request promise.
	*/
	public updateGroupBookingStatus = (id: string, status: 'PREBOOKED' | 'BOOKED' | 'REJECTED'): Promise<AxiosResponse<boolean>> => {
		return this._post('groups', {
			id,
			status
		}, {
			headers: {
				'Authorization': `Bearer ${this._password}`
			}
		});
	}

	
    public downloadQuoteFile = (booking: IGroupBooking) => {
		const postUrl = `${this.getBaseUrl()}/desk/quote/${booking.bookingId}/${booking.quoteId}?authorization=${this._password}`;
		const logoutForm = document.createElement('form');
		logoutForm.action = postUrl;
		logoutForm.id = "getquotefile";
		logoutForm.method = 'POST';
		logoutForm.target = '_blank';
		logoutForm.style.display = 'none';
		logoutForm.addEventListener('submit', (e) => {
			console.log('submit / download quote file');
		});

		document.body.append(logoutForm);
		logoutForm.submit();
		logoutForm.remove();
	}
}

export default BookingsApi;
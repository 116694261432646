import React from 'react';
import { Calendar, dayjsLocalizer } from 'react-big-calendar';
import { IBookingSummary } from '../../models/IBookingSummary';
import dayjs from 'dayjs'

const localizer = dayjsLocalizer(dayjs)

interface BookingCalendarProps {
    items: IBookingSummary[];
    changeCalendarDay: (newDate: Date) => void;
}

const minDate = dayjs().set('hour', 9).set('minute', 0).set('second', 0);
const maxDate = minDate.add(10, 'hour');

const BookingCalendar: React.FC<BookingCalendarProps> = (props: BookingCalendarProps) => {
    const eventStyleGetter = (event: any, start: Date, end: Date, isSelected?: boolean) => {
        console.log(event);
        var backgroundColor = 'blue';
        var forecolor = 'black';
        switch (event.kind) {
            case "Groupe":
                if (start.getHours() < 12)
                    backgroundColor = '#fffd01';
                else
                    backgroundColor = '#4efd54';
                break;
            case "Anniversaire":
                backgroundColor = '#fe019a';
                break;
            case "Atelier":
                const loweredTitle = event.title.toLowerCase();
                if (loweredTitle.indexOf('perma') > 0) {
                    backgroundColor = '#7f5112';
                    forecolor = 'white';
                }
                if (loweredTitle.indexOf('mini fermier') >= 0) {
                    backgroundColor = '#0ad6ff';
                    forecolor = 'white';
                }
                break;
        }
        var style = {
            backgroundColor: backgroundColor,
            color: forecolor
        };
        return {
            style: style
        };
    }

    const dayStyleGetter = (currentDate: Date) => {
        if (currentDate.getDay() === 6 || currentDate.getDay() === 0) {
            return {
                style: {
                    backgroundColor: '#F0F0F0', 
                }
            }
        }
        return {
            style: {}
        }
    }

    return (
        <div>
            <Calendar
                localizer={localizer}
                events={props.items.filter(e => e.nbAttendees > 0).map(e => {
                    return {
                        title: `${e.eventName} (${e.nbAttendees})${(e.options && e.options.length > 0) ? '\r\n' + e.options.join(',') : ''}`,
                        start: new Date(e.start),
                        end: new Date(e.end),
                        kind: e.kind
                    };
                })}
                startAccessor="start"
                endAccessor="end"
                defaultView={"week"}
                step={30}
                culture={'fr'}
                messages={{
                    week: 'Semaine',
                    work_week: 'Semaine de travail',
                    day: 'Jour',
                    month: 'Mois',
                    previous: 'Précédente',
                    next: 'Prochaine',
                    today: `Aujourd'hui`,
                    agenda: 'Ordre du jour',
                    showMore: (total) => `+${total} plus`
                }}
                min={minDate.toDate()}
                max={maxDate.toDate()}
                eventPropGetter={eventStyleGetter}
                dayPropGetter={dayStyleGetter}
                onNavigate={props.changeCalendarDay}
                resourceTitleAccessor={(res) => { return "toto"; }}
                formats={{
                    dayFormat: (date, culture, localizer) => {
                        return dayjs(date).format('ddd DD')
                    },
                    dayRangeHeaderFormat: (range, culture, localizer) => {
                        if (range.end.getMonth() !== range.start.getMonth())
                            return `du ${dayjs(range.start).format('DD MMMM')} au ${dayjs(range.end).format('DD MMMM')}`
                        else
                        return `du ${dayjs(range.start).format('DD')} au ${dayjs(range.end).format('DD MMMM')}`
                    }
                }}
                views={{
                    month: false,
                    week: true,
                    day: false,
                    work_week: false,
                    agenda: false
                }}
                style={{ height: 500 }}
            />
        </div>);
}

export default BookingCalendar;


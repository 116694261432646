import React, { useEffect, useState } from 'react';
import { Table } from 'semantic-ui-react';
import StatsApi from '../../api/StatsApi';
import IVisitBookingStatsPerCity from '../../models/IVisitBookingStatsPerCity';

interface StatsPerCityProps {
    api: StatsApi;
}

const StatsPerCity: React.FC<StatsPerCityProps> = (props: StatsPerCityProps) => {
    const [items, setItems] = useState<IVisitBookingStatsPerCity[]>();

    useEffect(() => {
        if (props.api && !items) {
            props.api.getVisitBookingsStatsPerCity()
                .then((res) => {
                    setItems(res.data);
                });
        }
    }, [props.api, items]);

    return (
        <>
            {items && (
                <Table celled striped unstackable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>CP</Table.HeaderCell>
                            <Table.HeaderCell>Ville</Table.HeaderCell>
                            <Table.HeaderCell>Visiteurs</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {items.slice(0, 10).map(i => {
                            return (
                                <Table.Row key={i.zipCode}>
                                    <Table.Cell>{i.zipCode}</Table.Cell>
                                    <Table.Cell>{i.city}</Table.Cell>
                                    <Table.Cell collapsing textAlign="right">{i.visitors}</Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            )}
        </>
    );
}

export default StatsPerCity;

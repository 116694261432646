import React, { useEffect, useState } from 'react';
import { IVisitBooking } from '../../models/IVisitBooking';
import { Checkbox, Icon, Accordion, Segment, Container } from 'semantic-ui-react';
import uniq from 'lodash/uniq';
import BookingDetails from '../bookingDetails/BookingDetails';
import dayjs from 'dayjs';
import 'dayjs/locale/fr'; // import locale

dayjs.locale('fr') // use locale

interface VisitBookingsListProps {
    items: IVisitBooking[];
    displayDate: boolean;
    updateShow: (id: string, show: boolean) => void;
};

const VisitBookingsList: React.FC<VisitBookingsListProps> = (props: VisitBookingsListProps) => {
    const [activeIndex, setActiveIndex] = useState<number>(props.displayDate ? 0 : - 1); // displayDate = true only if today
    const [currentActiveIndex, setCurrentActiveIndex] = useState<number>(props.displayDate ? 0 : - 1); // displayDate = true ony if today
    const dates = uniq(props.items.map(i => i.eventDate)).sort();

    useEffect(() => {
        const curDate = new Date();
        const curHours = curDate.getHours();
        const curMinutes = curDate.getMinutes();

        if (dates && dates.length > 0 && activeIndex < 0) {
            let newIndex = -1;

            dates.forEach((d, idx) => {
                const dtResa = dayjs(d);
                const startHour = dtResa.hour();
                if ((curHours > startHour) || (curHours === startHour && curMinutes < 30) || (curHours === (startHour - 1) && curMinutes >= 30)) {
                    newIndex = idx;
                }
            });

            setActiveIndex(newIndex === -1 ? dates.length - 1 : newIndex);
            setCurrentActiveIndex(newIndex === -1 ? dates.length - 1 : newIndex);
        }
    }, [activeIndex, dates]);

    return (
        <>
            {
                props.items.length === 0 && (
                    <span>Aucune réservation</span>
                )
            }
            { dates.length > 0 && (
                <>
                <Container fluid textAlign="center"><Icon name="calendar times outline" color="green" style={{marginBottom: 15}} /> Complet, <Icon name="calendar alternate outline" color="green" /> &gt;= 20 p., <Icon name="calendar outline" color="green" /> &lt; 20</Container>
                <Accordion styled fluid>
                    { dates.map((d, idx) => {
                        const curItems = props.items.filter(i => i.eventDate === d);
                        const dtResa = dayjs(d);
                        
                        let totals = {
                            individualNLG: 0,
                            individualHNLG: 0,
                            familyNLG: 0,
                            familyHNLG: 0,
                            free: 0
                        };
                        let totalsMissing = {
                            individualNLG: 0,
                            individualHNLG: 0,
                            familyNLG: 0,
                            familyHNLG: 0,
                            free: 0
                        };

                        curItems.forEach((resa) => {
                            totals.individualNLG += resa.individualNLG;
                            totals.individualHNLG += resa.individualHNLG;
                            totals.familyNLG += resa.familyNLG;
                            totals.familyHNLG += resa.familyHNLG;
                            totals.free += resa.free;

                            if (!resa.show) {
                                totalsMissing.individualNLG += resa.individualNLG;
                                totalsMissing.individualHNLG += resa.individualHNLG;
                                totalsMissing.familyNLG += resa.familyNLG;
                                totalsMissing.familyHNLG += resa.familyHNLG;
                                totalsMissing.free += resa.free;
                            }
                        })
                        const totalPersonsWithoutFree = totals.individualNLG + totals.individualHNLG + ((totals.familyNLG + totals.familyHNLG) * 5);
                        const totalPersons = totalPersonsWithoutFree + totals.free;

                        return (
                            <React.Fragment key={`resa-${idx}`}>
                                <Accordion.Title active={activeIndex === idx} index={idx} onClick={() => setActiveIndex(idx !== activeIndex ? idx : 10000)}>
                                    <Icon name='dropdown' />
                                    {props.displayDate && (<span>{dtResa.format('ddd DD MMM')} - </span>)}
                                        {dtResa.format('HH')}-{dtResa.add(1, 'hour').format('HH')}h - <b>{totalPersons} pers.</b> 
                                            <Icon name={totalPersonsWithoutFree >= 30 ? "calendar times outline" : (totalPersonsWithoutFree >= 20 ? "calendar alternate outline" : "calendar outline")} style={{marginLeft: 5}} color="green" />
                                            <Icon name="user" size="small" style={{marginLeft: 5}} />{totals.individualNLG + totals.individualHNLG}{(totalsMissing.individualNLG + totalsMissing.individualHNLG) > 0 && (<span className="missing">/{(totalsMissing.individualNLG + totalsMissing.individualHNLG)}</span>)} 
                                            <Icon name="group" style={{marginLeft: 5}} />{totals.familyNLG + totals.familyHNLG}{(totalsMissing.familyNLG + totalsMissing.familyHNLG > 0) && (<span className="missing">/{(totalsMissing.familyNLG + totalsMissing.familyHNLG)}</span>)} 
                                            <Icon name="child" style={{marginLeft: 5}} />{totals.free}{totalsMissing.free > 0 && (<span className="missing">/{totalsMissing.free}</span>)}
                                </Accordion.Title>
                                <Accordion.Content active={activeIndex === idx}>
                                    {curItems.sort((a, b) => (a.show ? 1: 0) - (b.show ? 1 : 0)).map((resa) => {
                                        const doneAndOld = (idx < currentActiveIndex && resa.show);
                                        const zipCodeNotCorresponding = (resa.zipCode !== '93160' && (resa.individualNLG + resa.familyNLG > 0));
                                        return (
                                            <Segment className='reservation' key={resa.id} style={{padding: 2}} color={idx >= currentActiveIndex && !resa.show ? 'orange' : undefined }>
                                                { doneAndOld && (
                                                    <div style={{ padding: 5, minHeight: 30 }}>
                                                        <span style={{ marginLeft: 5 }}>
                                                            {(resa.lastname || '').toUpperCase()} {resa.firstname} ({resa.zipCode})
                                                            <Icon name="user" size="small" style={{marginLeft: 5}} />{resa.individualNLG + resa.individualHNLG}
                                                            <Icon name="group" style={{marginLeft: 5}} />{resa.familyNLG + resa.familyHNLG}
                                                            <Icon name="child" style={{marginLeft: 5}}/>{resa.free}
                                                        </span>
                                                    </div>
                                                )}
                                                { !doneAndOld && (
                                                    <>
                                                        <div style={{ padding: 5, minHeight: 30 }}>
                                                            <Checkbox toggle defaultChecked={resa.show} onChange={(ev, cprops) => {
                                                                props.updateShow(resa.bookingId, cprops.checked !== undefined ? cprops.checked : true);
                                                            }} />
                                                            <span style={{ marginLeft: 5, color: zipCodeNotCorresponding ? 'red' : 'inherit', fontWeight: zipCodeNotCorresponding ? 700 : 300 }}>
                                                                {(resa.lastname || '').toUpperCase()} {resa.firstname} ({resa.zipCode})
                                                                <a style={{marginLeft: 3, marginRight: 3}} href={`mailto:${resa.email}`}><Icon name="mail" color="grey" /></a> 
                                                                { resa.phone && (
                                                                    <a href={`tel:${resa.phone}`}><Icon name="phone" color="grey" /></a> 
                                                                )}
                                                            </span>
                                                        </div>
                                                        <div style={{ padding: 5 }}>
                                                            <BookingDetails nbIndividualsNLG={resa.individualNLG} nbFamiliesNLG={resa.familyNLG} nbIndividualsHNLG={resa.individualHNLG} nbFamiliesHNLG={resa.familyHNLG} nbFree={resa.free} />
                                                        </div>
                                                    </>
                                                )}
                                                
                                            </Segment>
                                        );
                                    })}
                                </Accordion.Content>
                            </React.Fragment>);
                    })
                    }
                </Accordion>
                </>)
            }
        </>
    );
}

export default VisitBookingsList;
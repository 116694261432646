import React, { useState } from 'react';
import { IBirthdayBooking } from '../../models/IBirthdayBooking';
import { Checkbox, Icon, Accordion, Segment } from 'semantic-ui-react';
import uniq from 'lodash/uniq';
import dayjs from 'dayjs';
import 'dayjs/locale/fr'; // import locale
import utc from 'dayjs/plugin/utc' // import utc module

dayjs.extend(utc);
dayjs.locale('fr') // use locale fr

interface BirthdayBookingsListProps {
    items: IBirthdayBooking[];
    displayDate: boolean;
    updateShow: (id: string, show: boolean) => void;
};

const BirthdayBookingsList: React.FC<BirthdayBookingsListProps> = (props: BirthdayBookingsListProps) => {
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const dates = uniq(props.items.map(i => i.eventDate)).sort();

    return (
        <>
            {
                props.items.length === 0 && (
                    <span>Aucune réservation</span>
                )
            }
            { dates.length > 0 && (
                <Accordion styled>
                    { dates.map((d, idx) => {
                        const curItems = props.items.filter(i => i.eventDate === d);
                        const dtResa = dayjs.utc(d);

                        return (
                            <React.Fragment key={`resa-${idx}`}>
                                <Accordion.Title active={activeIndex === idx} index={idx} onClick={() => setActiveIndex(idx)}>
                                    <Icon name='dropdown' />
                                    {props.displayDate && (<span>{dtResa.local().format('ddd DD MMM')} - </span>)}
                                    {dtResa.local().format('HH')}-{dtResa.local().add(2, 'hour').format('HH')}h
                                </Accordion.Title>
                                <Accordion.Content active={activeIndex === idx}>
                                    {curItems.map((resa) => {
                                        return (
                                            <Segment className='reservation' key={resa.id} style={{padding: 2}}>
                                                <div style={{ padding: 5, minHeight: 30 }}>
                                                    <Checkbox toggle defaultChecked={resa.show} onChange={(ev, cprops) => {
                                                        props.updateShow(resa.bookingId, cprops.checked !== undefined ? cprops.checked : true);
                                                    }} />
                                                    <span style={{ marginLeft: 5 }}>
                                                        {(resa.lastname || '').toUpperCase()} {resa.firstname}
                                                        <a style={{marginLeft: 3, marginRight: 3}} href={`mailto:${resa.email}`}><Icon name="mail" color="grey" size="large"></Icon></a> 
                                                        { resa.phone && (
                                                            <a href={`tel:${resa.phone}`}><Icon name="phone" color="grey" size="large"></Icon></a> 
                                                        )}
                                                    </span>
                                                    <div style={{ padding: 5 }}>Réservé le {new Date(resa.created).toLocaleDateString()}
                                                        </div>
                                                </div>
                                            </Segment>
                                        );
                                    })}
                                </Accordion.Content>
                            </React.Fragment>);
                    })
                    }
                </Accordion>)
            }
        </>
    );
}

export default BirthdayBookingsList;
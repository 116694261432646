import { AxiosResponse } from 'axios';
import IVisitBookingStatsPerCity from '../models/IVisitBookingStatsPerCity';
import IVisitBookingStatsPerDay from '../models/IVisitBookingStatsPerDay';
import IVisitBookingStatsPerReferrer from '../models/IVisitBookingStatsPerReferrer';
import BaseApi from './BaseApi';

/**
 * The StatsApi class.
 *
 */
class StatsApi extends BaseApi {
	private _password: string;
	constructor(password: string) {
		super('desk/stats');
		this._password = password;
	}

	/** 
	 * Get stats visit booking per city
	 *  
	 * @return {Promise} The request promise.
	*/
	public getVisitBookingsStatsPerCity = (startingFrom?: Date): Promise<AxiosResponse<IVisitBookingStatsPerCity[]>> => {
		return this._get(`city`, {
			params: {
				startingFrom
			},
			headers: {
				'Authorization': `Bearer ${this._password}`
			}
		});
	}

	/** 
	 * Get stats visit booking per days
	 *  
	 * @return {Promise} The request promise.
	*/
	public getVisitBookingsStatsPerDay = (startingFrom?: Date): Promise<AxiosResponse<IVisitBookingStatsPerDay[]>> => {
		return this._get(`days`, {
			params: {
				startingFrom
			},
			headers: {
				'Authorization': `Bearer ${this._password}`
			}
		});
	}

	/** 
	 * Get stats visit booking per months
	 *  
	 * @return {Promise} The request promise.
	*/
	public getVisitBookingsStatsPerMonth = (startingFrom?: Date): Promise<AxiosResponse<IVisitBookingStatsPerDay[]>> => {
		return this._get(`months`, {
			params: {
				startingFrom
			},
			headers: {
				'Authorization': `Bearer ${this._password}`
			}
		});
	}
	/** 
	 * Get stats about referrers
	 *  
	 * @return {Promise} The request promise.
	*/
	public getVisitBookingsStatsPerReferrer = (startingFrom?: Date): Promise<AxiosResponse<IVisitBookingStatsPerReferrer[]>> => {
		return this._get(`referrer`, {
			params: {
				startingFrom
			},
			headers: {
				'Authorization': `Bearer ${this._password}`
			}
		});
	}
}

export default StatsApi;
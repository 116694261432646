import React, { useState } from 'react';
import { Checkbox, Icon, Accordion, Segment } from 'semantic-ui-react';
import uniq from 'lodash/uniq';
import { default as dayjs } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import 'dayjs/locale/fr';
import { ISpecialEvent } from '../../models/ISpecialEvent';

interface SpecialEventsListProps {
    items: ISpecialEvent[];
    updateShow: (id: string, show: boolean) => void;
};

dayjs.extend(isoWeek);
dayjs.locale('fr') // use locale

const SpecialEventsList: React.FC<SpecialEventsListProps> = (props: SpecialEventsListProps) => {
    const { items, updateShow } = props;
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const dates = uniq(items.map(i => dayjs(i.eventDate).isoWeek())).sort();

    return (
        <>
            {
                items.length === 0 && (
                    <span>Aucune réservation</span>
                )
            }
            {dates.length > 0 && (
                <Accordion styled>
                    {dates.map((d, idx) => {
                        const curItems = items.filter(i => dayjs(i.eventDate).isoWeek() === d).sort((a, b) => a.eventDate === b.eventDate ? 0 : (new Date(a.eventDate) < new Date(b.eventDate) ? -1 : 1));
                        const firstItemDate = dayjs(curItems[0].eventDate);
                        const firstItemDateDay = firstItemDate.day();
                        const firstDayOfWeek = firstItemDateDay === 1 ? firstItemDate : ((firstItemDateDay === 0) ? firstItemDate.add(1, 'd') : firstItemDate.add((-1 * firstItemDateDay) + 1, 'd'));

                        return (
                            <React.Fragment key={`resa-${idx}`}>
                                <Accordion.Title active={activeIndex === idx} index={idx} onClick={() => setActiveIndex(idx)}>
                                    <Icon name='dropdown' />
                                    <span>Semaine du {firstDayOfWeek.format('DD MMMM YYYY')}</span>
                                </Accordion.Title>
                                <Accordion.Content active={activeIndex === idx}>
                                    {curItems.map((specialEvent) => {
                                        const sumResas = specialEvent.bookings.reduce((se, val) => {
                                            return se + val.seats;
                                        }, 0);
                                        return (
                                            <Segment className='reservation' key={specialEvent.id} style={{ padding: 2 }}>
                                                <div style={{ padding: 5 }}><b>{specialEvent.title}</b></div>
                                                <div style={{ padding: '0 5' }}>Total résas: <b>{sumResas}</b></div>
                                                <div style={{ padding: 5, minHeight: 30 }}>
                                                    {specialEvent.bookings.map((booking) => {
                                                        return (
                                                            <div style={{ marginBottom: 10 }}>
                                                                <div>
                                                                    <Checkbox
                                                                        style={{ marginRight: 10 }}
                                                                        toggle
                                                                        defaultChecked={booking.show}
                                                                        onChange={(ev, cprops) => {
                                                                            updateShow(booking.bookingId, cprops.checked !== undefined ? cprops.checked : true);
                                                                        }} />
                                                                    {booking.firstname} {booking.lastname}
                                                                </div>
                                                                <div>Nbre résas: <b>{booking.seats}</b></div>
                                                            </div>
                                                        );
                                                    })}

                                                </div>
                                            </Segment>
                                        );
                                    })}
                                </Accordion.Content>
                            </React.Fragment>);
                    })
                    }
                </Accordion>)
            }
        </>
    );
}

export default SpecialEventsList;
import { AxiosResponse } from 'axios';
import BaseApi from './BaseApi';

/**
 * The AuthenticationApi class.
 *
 */
class AuthenticationApi extends BaseApi {
	constructor() {
		super('desk/authenticate');
	}

	/** 
	 * Authenticate user
	 *  
	 * @return {Promise} The request promise.
	*/
	public authenticate = (password: string): Promise<AxiosResponse<boolean>> => {
		return this._get('', {
			headers: {
				'Authorization': `Bearer ${password}`
			}
		});
	}
}

export default AuthenticationApi;
import React from 'react';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Label from 'semantic-ui-react/dist/commonjs/elements/Label';

interface BookingDetailsProps {
    nbIndividualsNLG: number;
    nbFamiliesNLG: number;
    nbIndividualsHNLG: number;
    nbFamiliesHNLG: number;
    nbFree: number;
}

const BookingDetails: React.FC<BookingDetailsProps> = (props: BookingDetailsProps) => {

    return (
        <>
        { (props.nbIndividualsNLG > 0 || props.nbFamiliesNLG > 0) && (
        <Button as='div' labelPosition='right'>
          <Button color={'green'} style={{paddingLeft: 15, paddingRight: 15}}>
            <Icon name="user" />
            <Icon name="group" />
              NLG
          </Button>
          <Label as='a' basic color={'green'} pointing='left'>
            {props.nbIndividualsNLG} / {props.nbFamiliesNLG}
          </Label>
        </Button>)}
        { (props.nbIndividualsHNLG > 0 || props.nbFamiliesHNLG > 0) && (
        <Button as='div' labelPosition='right'>
          <Button color={'blue'} style={{paddingLeft: 15, paddingRight: 15}}>
            <Icon name="user" />
            <Icon name="group" />
              HNLG
          </Button>
          <Label as='a' basic color={'blue'} pointing='left'>
            {props.nbIndividualsHNLG} / {props.nbFamiliesHNLG}
          </Label>
        </Button>)}
        {props.nbFree > 0 && (
            <Button as='div' labelPosition="right">
                <Button color="purple" icon='child'></Button>
                <Label as='a' basic color="purple" pointing='left'>
                    {props.nbFree}
                </Label>
            </Button>
        )}
        </>
    );
}

export default BookingDetails;


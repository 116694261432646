import React, { useEffect, useRef, useState } from 'react';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button/Button';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment/Segment';
import AuthenticationApi from '../../api/AuthenticationApi';

interface LoginFormProps {
    initPassword: string | null;
    login: (password: string) => void;
};

const LoginForm: React.FC<LoginFormProps> = (props: LoginFormProps) => {
    const [errorMessage, setErrorMessage] = useState<string>();
    const [currentPassword, setCurrentPassword] = useState<string>();
    const authenticated = useRef<boolean>(false);
    const [authenticating, setAuthenticating] = useState<boolean>(props.initPassword !== null);

    useEffect(() => {
        if (props.initPassword && !currentPassword && !authenticated.current) {
            setCurrentPassword(props.initPassword);
            setAuthenticating(true);
        }
    }, [props.initPassword, currentPassword]);

    useEffect(() => {
        if (currentPassword && authenticating) {
            const authenticate = () => {
                if (currentPassword && !authenticated.current) {
                    const bApi = new AuthenticationApi();
                    bApi.authenticate(currentPassword)
                        .then((res) => {
                            authenticated.current = true;
                            props.login(currentPassword);
                        }).catch((err) => {
                            if (err.response && err.response.status === 401)
                                setErrorMessage('Mot de passe invalide');
                            else 
                                setErrorMessage(err.message);
                        }).finally(() => {
                            setAuthenticating(false);
                        });
                }
            };

            setErrorMessage(undefined);
            authenticate();
        }
    }, [currentPassword, authenticating, props]);

    return (<>
        {!authenticating && (
            <Segment textAlign="center">
                <form>
                <h4>Authentification</h4>
                <div>Saisissez le mot de passe:</div>
                <input type='password' value={currentPassword} onChange={(ev) => { setCurrentPassword(ev.target.value); }} />
                <br />
                <Button onClick={() => setAuthenticating(true)} primary>Valider</Button>
                </form>
            </Segment>
        )}
        {authenticating && (
            <Segment loading>Authentification en cours...</Segment>
        )}
        {errorMessage && (
            <Segment negative={true} color={'red'}>{errorMessage}</Segment>
        )}
    </>);
};

export default LoginForm;
import Axios, { AxiosRequestConfig } from 'axios';

/**
 * The baseAPI class.
 * 
 * @class
 * @param {string} _scope   The targeted api scope.
 * @param {string} _baseUrl The base url.
 */
class BaseApi {

	private scope?: string;
	private baseUrl?: string;

	constructor(_scope: string, _baseUrl?: string) {
		this.scope = _scope === '' ? _scope : '/' + _scope;
		this.baseUrl = _baseUrl ? _baseUrl : (document.location.href.indexOf('test=1') >= 0 ? 'https://api-uat.fermerainbow.com/api' : (document.location.host === 'localhost:3200' ? 'https://localhost:5001/api' : 'https://api.fermerainbow.com/api'));
	}

	protected getBaseUrl() {
		return this.baseUrl;
	}
	/**
    * Encapsulation of the axios get request.
    * 
    * @param   {string}  endpoint request endpoint.
    * @param   {any}     params   request params.
    * @returns {Promise} The request promise.
    */
	protected _get(endpoint: string, config: AxiosRequestConfig): Promise<any> {
		return Axios.get(`${this.baseUrl}${this.scope}${endpoint === '' ? endpoint : '/' + endpoint}`, config);
	}

	/**
    * Encapsulation of the axios post request.
    * 
    * @param   {string}  endpoint request endpoint.
    * @param   {any}     data   request params.
    * @returns {Promise} The request promise.
    */
	protected _post(endpoint: string, data: any, config: AxiosRequestConfig): Promise<any> {
		return Axios.post(`${this.baseUrl}${this.scope}${endpoint === '' ? endpoint : '/' + endpoint}`, data, config);
	}
}

export default BaseApi;

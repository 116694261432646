import React, { useState } from 'react';
import { IGroupBooking } from '../../models/IGroupBooking';
import { Checkbox, Icon, Accordion, Segment, Select, DropdownItemProps } from 'semantic-ui-react';
import uniq from 'lodash/uniq';
import { default as dayjs } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import utc from 'dayjs/plugin/utc' // import utc module
import 'dayjs/locale/fr';

interface GroupBookingsListProps {
    items: IGroupBooking[];
    updateShow: (id: string, show: boolean) => void;
    updateStatus: (id: string, status: 'PREBOOKED' | 'BOOKED' | 'REJECTED') => Promise<boolean>;
    downloadQuoteFile: (booking: IGroupBooking) => void;
};

dayjs.extend(utc);
dayjs.extend(isoWeek);
dayjs.locale('fr') // use locale

const GroupBookingsList: React.FC<GroupBookingsListProps> = (props: GroupBookingsListProps) => {
    const { items, updateShow, updateStatus, downloadQuoteFile } = props;
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [updatingId, setUpdatingId] = useState<string>();
    const dates = uniq(items.map(i => {
        var d = dayjs.utc(i.eventDate).local();
        return `${d.year()}|${d.isoWeek().toString().padStart(3, '0')}`;
    })).sort();
    const selectOptions: DropdownItemProps[] = [{
        text: 'Confirmé',
        key: 'BOOKED',
        value: 'BOOKED'
    }, {
        text: 'Refusé',
        key: 'REJECTED',
        value: 'REJECTED'
    }];

    return (
        <>
            {
                items.length === 0 && (
                    <span>Aucune réservation</span>
                )
            }
            {dates.length > 0 && (
                <Accordion styled>
                    {dates.map((d, idx) => {
                        const dParts = d.split('|');
                        const year = parseInt(dParts[0]);
                        const isoWeek = parseInt(dParts[1]);
                        const curItems = items.filter(i => dayjs.utc(i.eventDate).local().isoWeek() === isoWeek && dayjs.utc(i.eventDate).local().year() === year).sort((a, b) => a.eventDate === b.eventDate ? 0 : (new Date(a.eventDate) < new Date(b.eventDate) ? -1 : 1));
                        const firstItemDate = dayjs.utc(curItems[0].eventDate).local();
                        const firstItemDateDay = firstItemDate.day();
                        const firstDayOfWeek = firstItemDateDay === 1 ? firstItemDate : ((firstItemDateDay === 0) ? firstItemDate.add(1, 'd') : firstItemDate.add((-1 * firstItemDateDay) + 1, 'd'));

                        return (
                            <React.Fragment key={`resa-${idx}`}>
                                <Accordion.Title active={activeIndex === idx} index={idx} onClick={() => setActiveIndex(idx)}>
                                    <Icon name='dropdown' />
                                    <span>Semaine du {firstDayOfWeek.format('DD MMMM YYYY')}</span>
                                </Accordion.Title>
                                <Accordion.Content active={activeIndex === idx}>
                                    {curItems.map((resa) => {
                                        const expired = !resa.quoteValidationDate && dayjs.utc(resa.quoteDueDate).local() < dayjs() && resa.status === 'REJECTED';
                                        return expired ? (
                                            <Segment className='reservation' key={resa.id} style={{ padding: 2 }} color='red'>
                                                <div style={{ padding: 5 }}>
                                                    <div>{resa.groupName}</div>
                                                    <div>Devis n°<b>{resa.quoteId ?? '?'}</b>&nbsp;-&nbsp;
                                                        <i style={{ color: 'red' }}>Expiré</i>
                                                    </div>
                                                </div>
                                            </Segment>
                                        ) :
                                            (<Segment className='reservation' key={resa.id} style={{ padding: 2 }} color={resa.status === 'BOOKED' ? 'green' : 'orange'}>
                                                <div style={{ padding: 5 }}>
                                                    <div><b>{dayjs.utc(resa.eventDate).local().format('dddd DD MMMM')} - {(resa.itemIdMorning !== '' && resa.itemIdAfternoon !== '') ? "Journée entière" : (resa.itemIdMorning !== '' ? "Matin" : "Après-midi")}</b></div>
                                                    <div><b>{resa.children}</b> enfants - <b>{resa.adults}</b> accompagnateurs</div>
                                                    { resa.options && (<div>+ <b>{resa.options}</b></div>)}
                                                </div>
                                                <div style={{ padding: 5 }}>
                                                    <div><b>{resa.groupName} &nbsp;{
                                                        resa.groupType === 'Etablissement scolaire' ?
                                                            <Icon name="graduation cap" /> :
                                                            (resa.groupType === 'Groupe thérapeutique' ? <Icon name="stethoscope" /> : <Icon name="bus" />)
                                                    }</b></div>
                                                    <div>{resa.city} ({resa.zipCode})</div>
                                                    <div>
                                                        {(resa.lastname || '').toUpperCase()} {resa.firstname}
                                                        <a style={{ marginLeft: 3, marginRight: 3 }} href={`mailto:${resa.email}`}><Icon name="mail" color="grey" size="large"></Icon></a>
                                                        {resa.phone && (
                                                            <a href={`tel:${resa.phone}`}><Icon name="phone" color="grey" size="large"></Icon></a>
                                                        )}
                                                    </div>
                                                </div>
                                                {resa.quoteValidationDate && (<>
                                                    <div style={{ padding: 5 }}>
                                                        <Select
                                                            options={selectOptions}
                                                            defaultValue={resa.status}
                                                            loading={updatingId === resa.bookingId}
                                                            onChange={(ev, data) => {
                                                                setUpdatingId(resa.bookingId);
                                                                updateStatus(resa.bookingId, (data.value as 'PREBOOKED' | 'BOOKED' | 'REJECTED'))
                                                                    .then((res: boolean) => {
                                                                        setUpdatingId(undefined);
                                                                    });
                                                            }}
                                                        />
                                                        <Checkbox
                                                            style={{ marginLeft: 20 }}
                                                            toggle
                                                            defaultChecked={resa.show}
                                                            onChange={(ev, cprops) => {
                                                                updateShow(resa.bookingId, cprops.checked !== undefined ? cprops.checked : true);
                                                            }} />
                                                    </div>
                                                </>)}
                                                <div style={{ padding: 5 }}>Devis n°<b>{resa.quoteId ?? '?'}&nbsp;
                                                    { (resa.quoteId && resa.quoteValidFilePath) && 
                                                        (<a href="#" onClick={() => downloadQuoteFile(resa)}>
                                                            <Icon name='download' />
                                                        </a>)
                                                    }
                                                    &nbsp;-&nbsp;
                                                    {
                                                        resa.quoteValidationDate ?
                                                            <b style={{ color: 'green' }}>Validé le {dayjs.utc(resa.quoteValidationDate).local().format('dddd DD MMMM')}</b> :
                                                            dayjs.utc(resa.quoteDueDate).local() > dayjs().local() ? (<b><i style={{ color: 'orange' }}>En attente signature</i></b>) : (<b><i style={{ color: 'black' }}>Expiré</i></b>)
                                                    }
                                                </b></div>
                                            </Segment>);
                                    })}
                                </Accordion.Content>
                            </React.Fragment>);
                    })
                    }
                </Accordion>)
            }
        </>
    );
}

export default GroupBookingsList;